import React from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '../../ui/collapsible';
import ProjectDetails from './ProjectDetails';
import VoiceAndTone from './VoiceAndTone';
import AdditionalOptions from './AdditionalOptions';

export default function AdvancedOptions({
  isOpen,
  setIsOpen,
  goal,
  setGoal,
  formFactor,
  setFormFactor,
  voiceAndToneProps,
  additionalOptionsProps
}) {
  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className="w-full space-y-2"
    >
      <CollapsibleTrigger className="flex items-center justify-between w-full p-2 font-medium text-left text-gray-900 bg-gray-100 rounded-lg hover:bg-gray-200">
        <span>Advanced Options</span>
        {isOpen ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
      </CollapsibleTrigger>
      <CollapsibleContent className="space-y-6 pt-8">
        <ProjectDetails goal={goal} setGoal={setGoal} formFactor={formFactor} setFormFactor={setFormFactor} />
        <VoiceAndTone {...voiceAndToneProps} />
        <AdditionalOptions {...additionalOptionsProps} />
      </CollapsibleContent>
    </Collapsible>
  );
}
