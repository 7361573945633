import React from 'react';

export default function BasicForm({ audience, setAudience, contentRequest, setContentRequest }) {
  return (
    <>
      <div className="relative">
        <select
          id="audience"
          value={audience}
          onChange={(e) => setAudience(e.target.value)}
          className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-rose-600"
          required
        >
          <option value="">Select Audience</option>
          <option value="substitute-teachers-w2">Substitute Teachers - W2</option>
          <option value="substitute-teachers-1099">Substitute Teachers - 1099</option>
          <option value="school-partners">School Partners</option>
        </select>
        <label htmlFor="audience" className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
          Audience
        </label>
      </div>
      <div className="relative">
        <textarea
          id="contentRequest"
          value={contentRequest}
          onChange={(e) => setContentRequest(e.target.value)}
          rows="4"
          className="peer h-auto w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-rose-600"
          placeholder="Enter your content request here..."
          required
        ></textarea>
        <label htmlFor="contentRequest" className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
          Content Request
        </label>
      </div>
    </>
  );
}
