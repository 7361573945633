import React from 'react';

export default function VoiceAndTone({
  formalCasual,
  setFormalCasual,
  seriousFunny,
  setSeriousFunny,
  respectfulIrreverent,
  setRespectfulIrreverent,
  matterOfFactEnthusiastic,
  setMatterOfFactEnthusiastic
}) {
  return (
    <div className="space-y-4">
      <h3 className="font-medium text-gray-900">Voice and Tone Adjustments</h3>
      
      <div className="space-y-2">
        <div className="flex justify-between text-sm text-gray-600">
          <span>Formal</span>
          <span>Casual</span>
        </div>
        <input
          type="range"
          min="0"
          max="100"
          value={formalCasual}
          onChange={(e) => setFormalCasual(Number(e.target.value))}
          className="w-full"
        />
      </div>

      <div className="space-y-2">
        <div className="flex justify-between text-sm text-gray-600">
          <span>Serious</span>
          <span>Funny</span>
        </div>
        <input
          type="range"
          min="0"
          max="100"
          value={seriousFunny}
          onChange={(e) => setSeriousFunny(Number(e.target.value))}
          className="w-full"
        />
      </div>

      <div className="space-y-2">
        <div className="flex justify-between text-sm text-gray-600">
          <span>Respectful</span>
          <span>Irreverent</span>
        </div>
        <input
          type="range"
          min="0"
          max="100"
          value={respectfulIrreverent}
          onChange={(e) => setRespectfulIrreverent(Number(e.target.value))}
          className="w-full"
        />
      </div>

      <div className="space-y-2">
        <div className="flex justify-between text-sm text-gray-600">
          <span>Matter of Fact</span>
          <span>Enthusiastic</span>
        </div>
        <input
          type="range"
          min="0"
          max="100"
          value={matterOfFactEnthusiastic}
          onChange={(e) => setMatterOfFactEnthusiastic(Number(e.target.value))}
          className="w-full"
        />
      </div>
    </div>
  );
}
