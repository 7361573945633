import React from 'react';

export default function ProjectDetails({ goal, setGoal, formFactor, setFormFactor }) {
  return (
    <div className="space-y-4">
      <div className="relative">
        <select
          id="goal"
          value={goal}
          onChange={(e) => setGoal(e.target.value)}
          className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-rose-600"
        >
          <option value="">Select Project Goal</option>
          <option value="inform">Inform</option>
          <option value="entertain">Entertain</option>
          <option value="drive-action">Drive action</option>
        </select>
        <label
          htmlFor="goal"
          className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
        >
          Project Goal
        </label>
      </div>

      <div className="relative">
        <select
          id="formFactor"
          value={formFactor}
          onChange={(e) => setFormFactor(e.target.value)}
          className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-rose-600"
        >
          <option value="">Select Form Factor</option>
          <option value="ad-unit">Ad unit</option>
          <option value="blog-post">Blog post</option>
          <option value="email-newsletter">Email newsletter</option>
          <option value="infographic">Infographic content</option>
          <option value="landing-page-copy">Landing page</option>
          <option value="one-pager">One-pager</option>
          <option value="podcast-outline">Podcast outline</option>
          <option value="press-release">Press release</option>
          <option value="product-description">Product description</option>
          <option value="social-post">Social post</option>
          <option value="video-script">Video script</option>
          <option value="white-paper">White paper</option>
        </select>
        <label
          htmlFor="formFactor"
          className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
        >
          Form Factor
        </label>
      </div>
    </div>
  );
}
