import React from 'react';

export default function AdditionalOptions({
  geography,
  setGeography,
  additionalOption2,
  setAdditionalOption2,
  freeFormText,
  setFreeFormText
}) {
  return (
    <div className="space-y-4">
      <h3 className="font-medium text-gray-900">Additional Options</h3>
      
      <div className="relative">
        <input
          type="text"
          id="geography"
          value={geography}
          onChange={(e) => setGeography(e.target.value)}
          className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-rose-600"
          placeholder="Geography"
        />
        <label
          htmlFor="geography"
          className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
        >
          Geography
        </label>
        <p className="mt-1 text-xs text-gray-500">
          Add a location and the generator will make subtle geographic adjustments where appropriate
        </p>
      </div>

      <div className="relative">
        <input
          type="text"
          id="seoKeywords"
          value={additionalOption2}
          onChange={(e) => setAdditionalOption2(e.target.value)}
          className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-rose-600"
          placeholder="SEO Keywords"
        />
        <label
          htmlFor="seoKeywords"
          className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
        >
          SEO Keywords
        </label>
        <p className="mt-1 text-xs text-gray-500">
          Enter up to 20 keywords to attempt to rank for, separated by a comma
        </p>
      </div>

      <div className="relative">
        <textarea
          id="freeFormText"
          value={freeFormText}
          onChange={(e) => setFreeFormText(e.target.value)}
          rows="4"
          className="peer h-auto w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-rose-600"
          placeholder="Any additional instructions..."
        ></textarea>
        <label
          htmlFor="freeFormText"
          className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
        >
          Additional Instructions
        </label>
      </div>
    </div>
  );
}
