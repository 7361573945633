import React, { useState } from 'react';
import { AlertCircle, Check } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '../ui/alert';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import ContentReviewer from './ContentReviewer';
import BasicForm from './BrandAlignedContentGenerator/BasicForm';
import AdvancedOptions from './BrandAlignedContentGenerator/AdvancedOptions';

export default function BrandAlignedContentGenerator() {
  const [audience, setAudience] = useState('');
  const [contentRequest, setContentRequest] = useState('');
  const [output, setOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isAdvancedOptionsOpen, setIsAdvancedOptionsOpen] = useState(false);

  // New state for advanced options
  const [goal, setGoal] = useState('');
  const [formFactor, setFormFactor] = useState('');
  const [formalCasual, setFormalCasual] = useState(50);
  const [seriousFunny, setSeriousFunny] = useState(50);
  const [respectfulIrreverent, setRespectfulIrreverent] = useState(50);
  const [matterOfFactEnthusiastic, setMatterOfFactEnthusiastic] = useState(50);
  const [geography, setGeography] = useState('');
  const [additionalOption2, setAdditionalOption2] = useState('');
  const [freeFormText, setFreeFormText] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setOutput('');
  
    const requestData = {
      audience,
      query: contentRequest,
      goal,
      formFactor,
      voiceAndTone: {
        formalCasual,
        seriousFunny,
        respectfulIrreverent,
        matterOfFactEnthusiastic,
      },
      geography,
      additionalOption2,
      freeFormText,
    };
  
    // Log the data being sent to the Cloudflare worker
    console.log('Data being sent to Cloudflare worker:', requestData);

    try {
        const response = await fetch('https://brandguidelines.paul-maxwell-campbell.workers.dev', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        });
    
        const data = await response.json();
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        console.log('Raw voice and tone values:', data.rawVoiceAndTone);
        console.log('Interpreted instructions:', data.interpretedInstructions);
        setOutput(data.content);
      } catch (error) {
        setError('Error generating content. Please try again.');
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };

  return (
    <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto">
            <h1 className="text-2xl font-semibold text-center mb-6">Brand-Aligned Content Tool</h1>
            
            <Tabs defaultValue="generate" className="w-full">
              <TabsList className="grid w-full grid-cols-2">
                <TabsTrigger value="generate">Generate Content</TabsTrigger>
                <TabsTrigger value="review">Review Content</TabsTrigger>
              </TabsList>
              
              <TabsContent value="generate">
                <form onSubmit={handleSubmit} className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                  <BasicForm
                    audience={audience}
                    setAudience={setAudience}
                    contentRequest={contentRequest}
                    setContentRequest={setContentRequest}
                  />
                  
                  <AdvancedOptions
                    isOpen={isAdvancedOptionsOpen}
                    setIsOpen={setIsAdvancedOptionsOpen}
                    goal={goal}
                    setGoal={setGoal}
                    formFactor={formFactor}
                    setFormFactor={setFormFactor}
                    voiceAndToneProps={{
                      formalCasual,
                      setFormalCasual,
                      seriousFunny,
                      setSeriousFunny,
                      respectfulIrreverent,
                      setRespectfulIrreverent,
                      matterOfFactEnthusiastic,
                      setMatterOfFactEnthusiastic,
                    }}
                    additionalOptionsProps={{
                      geography,
                      setGeography,
                      additionalOption2,
                      setAdditionalOption2,
                      freeFormText,
                      setFreeFormText,
                    }}
                  />

                  <button type="submit" className="bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-300 w-full">
                    {isLoading ? 'Generating...' : 'Generate Content'}
                  </button>
                </form>
              </TabsContent>
              
              <TabsContent value="review">
                <ContentReviewer />
              </TabsContent>
            </Tabs>

            {error && (
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
            
            {output && (
              <Alert>
                <Check className="h-4 w-4" />
                <AlertTitle>Generated Content</AlertTitle>
                <AlertDescription className="mt-2 whitespace-pre-wrap">{output}</AlertDescription>
              </Alert>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}